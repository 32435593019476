<template>
    <div>
        <a-spin :spinning="loading">
			<Header :title="isEdit ? '修改用户' : '添加用户'" @back="onBack(false)"></Header>
            <a-form ref="formRef" :model="modelRef" @finish="onSubmit" style="margin-top: 20px;" :labelCol="{span: 4, xxl: 3}"
			:wrapperCol="{span: 16, xxl: 15 }">
				
				<a-form-item name="username" label="用户账户" :rules="[{required: true, message: '必填项不允许为空'}]">
				    <a-input :disabled="isEdit" v-model:value="modelRef.username" placeholder="请输入用户账户"/>
				</a-form-item>

              <a-form-item label="用户名" name="nickname" :rules="[{required: true, message: '必填项不允许为空'}]">
                <a-input :disabled="isSee" v-model:value="modelRef.nickname" placeholder="请输入用户名"/>
              </a-form-item>
				
				<a-form-item
				  label="密码"
				  name="password"
				  :rules="[{ required: true, message: '请输入密码' }]"
				>
				  <a-input-password :disabled="isSee" v-model:value="modelRef.password" placeholder="请输入密码"></a-input-password>
				</a-form-item>
				
				<a-form-item
          v-if="isEdit"
				  label="新密码"
				  name="newPassword"
				>
				  <a-input-password :disabled="isSee" v-model:value="modelRef.newPassword" placeholder="请输入密码"></a-input-password>
				</a-form-item>
				
				<a-form-item label="确认密码" v-if="isEdit">
					<a-input-password :disabled="isSee" v-model:value="confirm" placeholder="请输入确认密码"/>
				</a-form-item>
				
				<a-form-item label="是否启用" name="isDisabled">
					<a-switch :disabled="isSee" v-model:checked="modelRef.isDisabled" checked-children="启用" un-checked-children="禁用"></a-switch>
				</a-form-item>
				
				<a-form-item label="关联角色" name="roleIds">
					<a-select :disabled="isSee" v-model:value="modelRef.roleIds" mode="multiple" placeholder="请选择关联角色">
						<a-select-option v-for="item in roleList" :key="item.id" :value="item.id">{{ item.title }}</a-select-option>
					</a-select>
				</a-form-item>
				
				<a-form-item label="关联影院" name="cinemaIdList">
					<a-select :disabled="isSee" v-model:value="modelRef.cinemaIdList" placeholder="请选择关联影院" mode="multiple">
						<a-select-option v-for="item in cinemaList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
					</a-select>
				</a-form-item>

              <a-form-item label="小程序用户">
                <a-form-item-rest>
                  <selectBaseUser :disabled="isSee" v-model:value="idList" :selectItems="list" @change="(dataList) => {list = dataList}"></selectBaseUser>
                </a-form-item-rest>
                <div v-if="list.length" style="margin: 10px 0;">
                  <a-table rowKey="id" :columns="columns" :dataSource="list" size="small" :pagination="false">
                    <template #bodyCell="{ column, record, index }">
                      <template v-if="column.key === 'action'">
                        <a-button type="link" @click="onDelete(record, index)">删除</a-button>
                      </template>
                    </template>
                  </a-table>
                </div>
              </a-form-item>
				
                <div style="margin-top: 20px;text-align: center;">
                    <a-col :span="16">
						<a-button v-if="!isSee" type="primary" html-type="submit" :loading="loading" style="margin-right: 20px;">提交</a-button>
						<a-button @click="onBack(false)">返回</a-button>
					</a-col>
                </div>
            </a-form>
        </a-spin>
    </div>
</template>

<script>
	import Header from '@/components/header/header.vue';
	import { getCinemaList } from '@/service/modules/cinema.js';
  import * as systemApi from '@/service/modules/system';
  import selectBaseUser from '@/components/select/selectBaseUser/index.vue';
    export default {
		components: {Header, selectBaseUser},
        props: {
            isEdit: {      // true :编辑  false: 新增
                type: Boolean,
                default: false
            },
			id: {
				type: null,
				default: 0
			},
			isSee: {
				type: Boolean,
				default: false
			},
        },
        data() {
          return {
            loading: false,
            confirm: '',
            formItemLayout: {
              labelCol: { span: 6 },
              wrapperCol: { span: 14 },
            },
            modelRef: {
              title: '',
              roleIds: [],
              cinemaIdList: [],
              isDefault: false
            },
            cinemaList: [],
            roleList: [],
            idList: [],
            list: [],
            columns: [{
              title: '用户名称',
              dataIndex: 'nickname'
            }, {
              title: '用户电话',
              dataIndex: 'phone'
            }, {
              title: '操作',
              key: 'action'
            }],
          }
        },
        created() {
			    this.getRoleList();
          this.getCinemaList()
          if (this.id) {
            this.getData();
          }
        },
        methods: {
          onBack(isRef) {
            this.$emit('back', isRef);
          },
          onSubmit() {
            if(this.modelRef.newPassword !== this.confirm && this.modelRef.newPassword) {
              this.$message.error('密码输入错误！');
              return;
            }
            if(this.idList.length > 1) {
              this.$message.error('最多只能选择一个小程序用户！');
              return;
            }
            let postData = JSON.parse(JSON.stringify(this.modelRef));
            postData.isDisabled = postData.isDisabled ? 0 : 1;
            postData.roleIds = postData.roleIds.join(',');
            postData.baseUserId = this.idList.join(',');
            try {
              this.loading = true;
              if (this.isEdit) {
                // if (!postData.newPassword) {
                //   delete postData.newPassword;
                // }
                postData.id = this.id;
                systemApi.userUpdate(postData).then(res => {
                  this.loading = false;
                  if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.onBack(true);
                    // this.$closePage(this.$router.currentRoute.value, '/system/systemRoot/role/view');
                  }
                })
              } else {
                systemApi.userAdd(postData).then(res => {
                  this.loading = false;
                  if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.onBack(true);
                  }
                })
              }
            } catch(e) {
              this.loading = false;
            }
          },
          getData() {
            this.loading = true;
            systemApi.userDetail({id: this.id}).then(res => {
              this.loading = false;
              if (res.code == 200) {
                res.data.roleIds = res.data.adminRoleList.map(item => {
                  return item.id;
                });
                res.data.isDisabled = res.data.isDisabled ? false : true;
                this.modelRef = res.data;

                if(res.data.baseUserId) {
                  this.idList.push(res.data.baseUserId + '');
                }
                if(res.data.baseUserNickname || res.data.baseUserPhone) {
                  this.list.push({
                    nickname: res.data.baseUserNickname,
                    phone: res.data.baseUserPhone
                  })
                }
              }
            })
          },
			async getRoleList() {
				let ret = await systemApi.roleList({
					page: 1,
					pageSize: 999999
				});
				if (ret.code === 200) {
					this.roleList = ret.data.list;
				}
			},
			async getCinemaList() {
				this.modelRef.cinemaCodes = [];
				this.cinemaList = [];
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
				});
				if (ret.code === 200) {
					this.cinemaList = ret.data.list;
          this.cinemaList.unshift({
            id: 0,
            name: '全部',
          })
				}
			},
          onDelete(item, index) {
            let i = this.idList.indexOf(item.id);
            this.idList.splice(i, 1);
            this.list.splice(index, 1);
          },
        },
    }
</script>

<style scoped>
    .ui-roleEdit {
        width: 700px;
        text-align: left;
    }
</style>